import axios from 'axios';
import { buildUrl } from '../../helpers/utils';
import { urls } from './_accounting.configs';

export const getAccount = async (id, params = {}) => {
    params = {
        ...params,
        id,
    };
    const { data } = await axios.get(buildUrl(urls.account_details, params));
    return data;
};

export const getLoan = async (id) => {
    const { data } = await axios.get(
        buildUrl(urls.loan_details, {
            id,
            expand: ['loan_data'].join(),
        })
    );
    return data;
};

export const getRecords = async (params) => {
    const { data } = await axios.get(buildUrl(urls.records, params));
    return data;
};

export const listLoans = async (params) => {
    const { data } = await axios.get(urls.loans, {
        params,
    });
    return data;
};

export const getLoanSummary = async (id) => {
    const { data } = await axios.get(buildUrl(urls.loan_summary, { id }));
    return data;
};

export const getLedger = async (
    account_id,
    loan_id,
    record,
    date_min,
    date_max,
    ledgerType,
    transaction_date_min,
    transaction_date_max,
    types = 'disbursement,record',
    params
) => {
    const { data } = await axios.get(
        buildUrl(urls.ledger, {
            type__in: ledgerType,
            page_size: 200,
            account: account_id,
            loan: loan_id,
            record: record,
            date__gte: date_min,
            date__lte: date_max,
            transaction_date__gte: transaction_date_min,
            transaction_date__lte: transaction_date_max,
            expand: ['account.borrower', 'account.program', 'record', 'loan', 'loan.topup'].join(
                ','
            ),
            ...params,
        })
    );
    return data;
};

export const updateTopupLoanData = async (id, payload) => {
    const { data } = await axios.patch(buildUrl(urls.loan_data, { id }), payload);
    return data;
};

export const getDashboardSummary = async () => {
    const { data } = await axios.get(urls.dashboard_summary);
    return data;
};

export const getDashboardRepaymentsSummary = async (params = { frequency: 'daily' }) => {
    const { data } = await axios.get(buildUrl(urls.dashboard_repayments, params));
    return data;
};
export const getDashboardDisbursementsSummary = async (params = { frequency: 'daily' }) => {
    const { data } = await axios.get(buildUrl(urls.dashboard_disbursements, params));
    return data;
};

export const getFeeSettlements = async (id) => {
    const { data } = await axios.get(buildUrl(urls.fee_settlements, {id ,  expand: 'settlements'  }));
    return data;
}
