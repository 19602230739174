import React from 'react';
import { amount } from '../../helpers/str';
const GSTR2A = ({ details }) => {
    return (
        <div className='card'>
            <div className='card-header'>
                <div className='card-title'>Purchase Data (GSTR2A)</div>
            </div>
            <div className='card-body'>
                <div className='table-responsive'>
                    <table className='table table-bordered table-striped'>
                        <thead>
                            <tr>
                                <th>Month</th>
                                <th>Purchases</th>
                                <th>Debit Notes Issued</th>
                                <th>Credit Notes Issued</th>
                                <th>Adjustments</th>
                                <th>Gross Purchases</th>
                                <th>Input Tax Credits Available</th>
                            </tr>
                        </thead>
                        <tbody>
                            {details.map((row, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{row?.month}</td>
                                        <td>{amount(row?.purchases)}</td>
                                        <td>{amount(row?.debit_notes)}</td>
                                        <td>{amount(row?.credit_notes)}</td>
                                        <td>{amount(row?.adjustments)}</td>
                                        <td>{amount(row?.gross_purchases)}</td>
                                        <td>{amount(row?.input_tax_credits_available)}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default GSTR2A;
