import { useParams } from 'react-router-dom';
import DocumentFrame from '../../Storage/DocumentFrame';

const StoragePreview = () => {
    const { storage_id } = useParams();
    return (
        <DocumentFrame
            documents={[
                {
                    storage_id,
                },
            ]}
        />
    );
};
export default StoragePreview;
