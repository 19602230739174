export const CLASSES = {
    0: 'danger',
    1: 'warning',
    2: 'info',
    3: 'primary',
    4: 'success',

    y: 'warning',
    r: 'danger',
    g: 'success',

    // Status
    kyc_pending: 'warning',
    due: 'primary',
    pending_underwriting: 'info',
    activation: 'info',
    submit_cam: 'warning',
    approve_cam: 'info',
    accept_offer: 'success',
    kyc_approval: 'warning',
    kyc_verification: 'primary',
    pending_approval: 'warning',
    credit_approval: 'primary',
    bre_approval: 'primary',
    bre_pending: 'warning',
    bre_failed: 'danger',
    pending_disbursement: 'warning',
    pending: 'warning',
    rejected: 'danger',
    inactive: 'danger',
    hold: 'warning',
    failed: 'danger',
    error: 'danger',
    writeoff: 'danger',
    processed: 'info',
    pending_disbursal: 'info',
    scheduled: 'info',
    paid: 'success',
    partially_paid: 'info',
    dropped: 'danger',

    running: 'success',
    closed: 'danger',

    repayment: 'success',
    margin: 'warning',
    credit_note: 'info',
    tds: 'danger',
    adjustment: 'danger',

    approved: 'success',
    active: 'success',
    verified: 'success',
    kyc_verified: 'success',
    stamped: 'success',
    unsigned: 'danger',
    signed: 'success',
    disbursed: 'success',

    owned: 'success',
    rented: 'danger',
    current: 'primary',

    // Boolean Values
    true: 'success',
    false: 'danger',

    // Custom Status
    completed: 'primary',
    kyc_completed: 'primary',
    success: 'success',
    los: 'success',
    activated: 'success',
    initiate: 'primary',
    initiated: 'primary',
    drop: 'light-danger',
    reopen: 'warning',
    sanction: 'info',
    submitted: 'info',
    sanctioned: 'info',
    activate: 'success',

    // Business Verticals
    agriculture: 'success',
    grocery: 'danger',
    electronics: 'primary',
    logistics: 'info',
    automobiles: 'warning',
    medical: 'danger',
    apparels: 'info',
    testing: 'danger',
    hardwareservices: 'warning',
    manufacturer: 'warning',

    // Tags
    primary: 'success',
    dedupe: 'danger',
    back_date_invoice: 'warning',

    // Access Types
    system: 'primary',
    api: 'info',

    // User Types
    lender: 'success',
    anchor: 'info',

    // Actions
    created: 'success',
    updated: 'primary',
    deleted: 'danger',

    unassigned: 'primary',
    pending_for_approval: 'danger',

    debit: 'danger',
    credit: 'success',

    nach_presented: 'primary',
    nach_bounced: 'danger',

    nap: 'danger',
    cancelled: 'danger',

    upcoming: 'info',
    unpaid: 'danger',

    npa: 'danger',
    cashback: 'success',

    invoice: 'info',
    vehicle: 'success',

    is_suspense_balance: 'warning',
    is_manually_matched: 'info',

    liability: 'danger',
    assets: 'success',

    bank: 'primary',
    interest_accrual: 'success',
    disbursement: 'danger',
    banking_analysis: 'success',
    mandate: 'info',
    excess_money: 'success',

    get: 'success',
    post: 'primary',
    patch: 'info',
    put: 'warning',
    options: 'warning',
    head: 'warning',
    delete: 'danger',
};
