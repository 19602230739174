import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getWarehouseDetails } from '../_rego.service';
import GstAverages from '../../Gst/GstAverages';
import GstSalesPurchaseChart from '../../Gst/GstSalesPurchaseChart';
import GstCompanies from '../../Gst/GstCompanies';
import GstStateWise from '../../Gst/GstStateWise';
import GSTR1 from '../../Gst/GSTR1';
import GSTR2A from '../../Gst/GSTR2A';

const SignzyGstAnalytics = () => {
    const { report_id } = useParams();
    const [report, setReport] = useState(null);

    const initWarehouseDetails = async () => {
        const { data } = await getWarehouseDetails({ warehouse_id: report_id });
        const jsonData = {
            report_details: {
                gstin: data?.data?.[0]?.['Account Details']?.GSTIN,
                pan: data?.data?.[0]?.['Account Details']?.PAN,
                taxpayer_type: data?.data?.[0]?.['Account Details']?.taxPayer,
                entity: data?.data?.[0]?.['Account Details']?.['GSTR Analysis Report  - '],
                from: data?.data?.[0]?.['Account Details']?.periodFrom,
                to: data?.data?.[0]?.['Account Details']?.periodTo,
                max_sales_states:
                    data?.data?.[0]?.['Account Details']?.[
                        'State of Operations(based on max. gross sales)'
                    ],
            },
            overview: data?.data?.[2]?.Overview_Monthly?.['Overview of GST Returns'].map((row) => {
                return {
                    month: row['Month Year'] === 'Total' ? 'Total' : row['Month Year'],
                    gross_profit: row?.['Gross Profit (K=E-J)'],
                    sales: row?.['GSTR 1 Gross Sales (E=A+B-C+D)'],
                    purchase: row?.['GSTR 2A Gross Purchases (J=F+G-H+I)'],
                };
            }),
            gstr1: data?.data?.[2]?.Overview_Monthly?.['Overview of GST Returns'].map((row) => {
                return {
                    month: row['Month Year'] === 'Total' ? 'Total' : row['Month Year'],
                    sales: row?.['Total Value of Sales (A)'],
                    debit_notes: row?.['GSTR 1 Total Value of Debit Notes Issued (B)'],
                    credit_notes: row?.['GSTR 1 Total Value of Credit Notes Issued (C)'],
                    adjustments: row?.['GSTR 1 Adjustments due to Amendments (D)'],
                    gross_sales: row?.['GSTR 1 Gross Sales (E=A+B-C+D)'],
                    gst_liability: row?.['Total GST Liability(L)'],
                };
            }),
            gstr2a: data?.data?.[2]?.Overview_Monthly?.['Overview of GST Returns'].map((row) => {
                return {
                    month: row['Month Year'] === 'Total' ? 'Total' : row['Month Year'],
                    purchases: row?.['GSTR 2A Total Value of Purchases (F)'],
                    debit_notes: row?.['GSTR 2A Total Value of Debit Notes Issued (G)'],
                    credit_notes: row?.['GSTR 2A Total Value of Credit Notes Issued(H)'],
                    adjustments: row?.['GSTR 2A Adjustments due to Amendments (I)'],
                    gross_purchases: row?.['GSTR 2A Gross Purchases (J=F+G-H+I)'],
                    input_tax_credits_available: row?.['Input Tax Credit Available(M)'],
                };
            }),
            averages: {
                sales: data?.data?.[3]?.Snapshot?.[3]?.Averages?.['Average Sales per Month'],
                purchases:
                    data?.data?.[3]?.Snapshot?.[3]?.Averages?.['Average Purchases per Month'],
                sales_per_customer:
                    data?.data?.[3]?.Snapshot?.[3]?.Averages?.['Average Sales per Customer'],
                purchases_per_supplier:
                    data?.data?.[3]?.Snapshot?.[3]?.Averages?.['Average Purchases per Supplier'],
            },
            invoices: {
                suppliers: data?.data?.[4]?.['Major Suppliers & Customers ']?.[0]?.[
                    '10 Major Suppliers'
                ]?.map((row) => {
                    return {
                        name: row?.['Name of Supplier'],
                        count: row?.['Total No. of invoices'],
                        sum: row?.['Total Amount '],
                    };
                }),
                customers: data?.data?.[4]?.['Major Suppliers & Customers ']?.[1]?.[
                    '10 Major Customers'
                ]?.map((row) => {
                    return {
                        name: row?.['Name of Customer'],
                        count: row?.['Total No. of invoices'],
                        sum: row?.['Total Amount '],
                    };
                }),
            },
            states: {
                suppliers: data?.data?.[8]?.['Supplier Summary']?.[1]?.[
                    'State-wise Supplier Frequency '
                ]?.map((row) => {
                    return {
                        name: row?.State,
                        sum: row?.['Taxable Value'],
                    };
                }),
                customers: data?.data?.[6]?.['Customer Summary']?.[1]?.[
                    'State-wise Customer Frequency '
                ]?.map((row) => {
                    return {
                        name: row?.State,
                        sum: row?.['Taxable Value'],
                    };
                }),
            },
        };
        setReport(jsonData);
    };

    useEffect(() => {
        initWarehouseDetails();
    }, []);

    return (
        <>
            {report && (
                <>
                    <div className='card mb-5'>
                        <div className='card-header'>
                            <div className='card-title'>
                                <h3>
                                    GST Overview for {report?.report_details?.from} -{' '}
                                    {report?.report_details?.to}
                                </h3>
                            </div>
                            <div className='card-toolbar'>
                                <a
                                    className='btn btn-sm btn-icon btn-success ms-2'
                                    target='_blank'
                                    rel='noreferrer'
                                    href={report?.files?.excel}>
                                    <i className='bi bi-file-earmark-spreadsheet'></i>
                                </a>
                                <a
                                    className='btn btn-sm btn-icon btn-danger ms-2'
                                    target='_blank'
                                    rel='noreferrer'
                                    href={report?.files?.pdf}>
                                    <i className='bi bi-file-earmark-pdf'></i>
                                </a>
                                <a
                                    className='btn btn-sm btn-icon btn-info ms-2'
                                    target='_blank'
                                    rel='noreferrer'
                                    href={report?.files?.json}>
                                    <i className='bi bi-braces-asterisk'></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className='mb-5'>
                        <GstAverages details={report?.averages} />
                    </div>
                    <div className='mb-5'>
                        <GstSalesPurchaseChart details={report?.overview} />
                    </div>
                    <div className='mb-5'>
                        <GstCompanies details={report?.invoices} />
                    </div>
                    <div className='mb-5'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <GstStateWise
                                    label='State Wise Customer Summary'
                                    details={report?.states?.customers}
                                />
                            </div>
                            <div className='col-md-6'>
                                <GstStateWise
                                    label='State Wise Supplier Summary'
                                    details={report?.states?.suppliers}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='mb-5'>
                        <GSTR1 details={report?.gstr1} />
                    </div>
                    <div className='mb-5'>
                        <GSTR2A details={report?.gstr2a} />
                    </div>
                </>
            )}
        </>
    );
};
export default SignzyGstAnalytics;
