import axios from 'axios';

export const get = (url, params) => {
    return axios.get(url, {
        params: params
    });
}

export const post = (url, data) => {
    return axios.post(url, data);
}

export const patch = (url, data) => {
    return axios.patch(url, data);
}

export const put = (url, data) => {
    return axios.put(url, data);
}

export const del = (url, data) => {
    return axios.delete(url, data);
}