import React from 'react';
import { Doughnut } from 'react-chartjs-2';

const GstStateWise = ({ label, details }) => {
    const labels = [];
    const datasets = [
        {
            label: '',
            data: [],
            backgroundColor: ['#fe6484', '#ff9e40', '#ffcd56', '#4cc0c0', '#37a2eb'],
        },
    ];
    details.forEach((row, index) => {
        labels.push(row.name);
        datasets?.[0]?.data?.push(row?.sum);
    });
    return (
        <div className='card'>
            <div className='card-header'>
                <div className='card-title'>{label}</div>
            </div>
            <div className='card-body px-20'>
                <Doughnut
                    data={{
                        labels,
                        datasets,
                    }}
                />
            </div>
        </div>
    );
};

export default GstStateWise;
