import { Route, Routes } from 'react-router-dom';
import SignzyGstAnalytics from './Widgets/SignzyGstAnalytics';
import StoragePreview from './Widgets/StoragePreview';

const Rego = () => {
    return (
        <Routes>
            <Route
                path='/signzy-gst-analytics/:report_id'
                element={<SignzyGstAnalytics />}
            />
            <Route
                path='/storage/:storage_id'
                element={<StoragePreview />}
            />
        </Routes>
    );
};
export default Rego;
