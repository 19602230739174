import React from 'react'
import { Modal, Button } from 'react-bootstrap'

const PopupView = ({ title, children, show, onHide, footer, onSubmit, btnText, largeSize, centered, backdrop }) => {
    return (
        <Modal show={show} onHide={onHide} scrollable backdrop={backdrop} centered={centered} size={ largeSize && "lg"}  >
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
            {footer &&  <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
                <Button variant="primary" onClick={onSubmit} >{btnText}</Button>
            </Modal.Footer>}
        </Modal>
    )
}

export default PopupView