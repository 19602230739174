import { beautify } from '../../helpers/str';
import { amountWords } from '../../helpers/utils';

const GstAverages = ({ details }) => {
    return (
        <div className='d-flex gap-5'>
            {Object.entries({
                sales: 'success',
                purchases: 'danger',
                sales_per_customer: 'info',
                purchases_per_supplier: 'warning',
            }).map(([key, className]) => {
                return (
                    <div
                        className='flex-fill'
                        key={key}>
                        <div className='card'>
                            <div className='card-body d-flex justify-content-between flex-column px-0 pb-0'>
                                <div className='mb-4 px-9'>
                                    <div className='d-flex align-items-center mb-2'>
                                        <span className='fs-4 fw-semibold text-gray-500 align-self-start me-1>'>
                                            ₹
                                        </span>
                                        <span
                                            className={`fs-2hx fw-bold me-2 lh-1 text-${className}`}>
                                            {amountWords(details?.[key], 2)}
                                        </span>
                                    </div>
                                    <span className='fs-6 fw-semibold text-gray-500'>
                                        {beautify(key)} Per Month
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default GstAverages;
