import React from 'react';
import { amount } from '../../helpers/str';
const GSTR1 = ({ details }) => {
    return (
        <div className='card'>
            <div className='card-header'>
                <div className='card-title'>Sales Data (GSTR1)</div>
            </div>
            <div className='card-body'>
                <div className='table-responsive'>
                    <table className='table table-bordered table-striped'>
                        <thead>
                            <tr>
                                <th>Month</th>
                                <th>Sales</th>
                                <th>Debit Notes</th>
                                <th>Credit Notes</th>
                                <th>Adjustments</th>
                                <th>Gross Sales</th>
                                <th>Total GST Liability</th>
                            </tr>
                        </thead>
                        <tbody>
                            {details.map((row, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{row?.month}</td>
                                        <td>{amount(row?.sales)}</td>
                                        <td>{amount(row?.debit_notes)}</td>
                                        <td>{amount(row?.credit_notes)}</td>
                                        <td>{amount(row?.adjustments)}</td>
                                        <td>{amount(row?.gross_sales)}</td>
                                        <td>{amount(row?.gst_liability)}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default GSTR1;
