import React from 'react';
import { Bar } from 'react-chartjs-2';

const GstSalesPurchaseChart = ({ details }) => {
    const labels = [];
    const datasets = [
        {
            label: 'Gross Profits',
            data: [],
            backgroundColor: ['#1b84ff'],
            borderRadius: 5,
            barThickness: 'flex',
            barPercentage: 1,
        },
        {
            label: 'Sales',
            data: [],
            backgroundColor: ['#17c653'],
            borderRadius: 5,
            barThickness: 'flex',
            barPercentage: 0.5,
        },
        {
            label: 'Purchase',
            data: [],
            backgroundColor: ['#f8295a'],
            borderRadius: 5,
            barThickness: 'flex',
            barPercentage: 0.5,
        },
    ];
    details.forEach((row, index) => {
        if (row.month !== 'Total') {
            labels.push(row?.month);
            datasets?.[0]?.data?.push(row?.gross_profit);
            datasets?.[1]?.data?.push(row?.sales);
            datasets?.[2]?.data?.push(row?.purchase);
        }
    });
    const chartData = {
        labels,
        datasets,
    };
    return (
        <div className='card'>
            <div className='card-header'>
                <div className='card-title'>Profits, Sales & Purchase History</div>
            </div>
            <div className='card-body'>
                <Bar data={chartData} />
            </div>
        </div>
    );
};

export default GstSalesPurchaseChart;
