import React from 'react';
import { getInitial } from '../../helpers/utils';
import { amount } from '../../helpers/str';

const GstCompanies = ({ details }) => {
    return (
        <div className='row'>
            <div className='col-md-6'>
                <div className='card h-100'>
                    <div className='card-header'>
                        <div className='card-title'>Top Customers</div>
                    </div>
                    <div className='card-body'>
                        {details?.customers?.map((row, key) => {
                            return (
                                <div
                                    className='d-flex align-items-sm-center mb-7'
                                    key={key}>
                                    <div className='symbol symbol-40px me-2'>
                                        <span className='symbol-label fs-3 bg-light-danger text-danger'>
                                            {getInitial(row?.name)}
                                        </span>
                                    </div>
                                    <div className='d-flex align-items-center flex-row-fluid'>
                                        <div className='flex-grow-1 me-2'>
                                            <span className='text-gray-800 text-hover-primary fs-6 fw-bold'>
                                                {row?.name}
                                            </span>
                                            <span className='text-muted fw-semibold d-block fs-7'>
                                                {row?.count} Invoices
                                            </span>
                                        </div>
                                        <span className='badge badge-light badge-light-danger fw-bold my-2'>
                                            {amount(row?.sum)}
                                        </span>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <div className='col-md-6'>
                <div className='card h-100'>
                    <div className='card-header'>
                        <div className='card-title'>Top Suppliers</div>
                    </div>
                    <div className='card-body'>
                        {details?.suppliers?.map((row, key) => {
                            return (
                                <div
                                    className='d-flex align-items-sm-center mb-7'
                                    key={key}>
                                    <div className='symbol symbol-40px me-2'>
                                        <span className='symbol-label fs-3 bg-light-success text-success'>
                                            {getInitial(row?.name)}
                                        </span>
                                    </div>
                                    <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
                                        <div className='flex-grow-1 me-2'>
                                            <span className='text-gray-800 text-hover-primary fs-6 fw-bold'>
                                                {row?.name}
                                            </span>
                                            <span className='text-muted fw-semibold d-block fs-7'>
                                                {row?.invoices} Invoices
                                            </span>
                                        </div>
                                        <span className='badge badge-light badge-light-success fw-bold my-2'>
                                            {amount(row?.sum)}
                                        </span>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GstCompanies;
