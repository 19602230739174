import React, { useEffect, useState } from 'react';
import { filename } from '../../helpers/str';
import { buildUrl } from '../../helpers/utils';
import { get } from '../../services/core';
import { urls } from './_storage.configs';

const DocumentFrame = ({ documents }) => {
    const [files, setFiles] = useState([]);
    const getData = async () => {
        const tmp = [];
        for (const document of documents) {
            let response = await get(
                buildUrl(urls.storage_documents_details, { id: document.storage_id })
            );
            response = response.data.response_data;
            for (const element of response) {
                try {
                    const { data } = await get(
                        buildUrl(urls.storage_file, { file_path: element.file_name })
                    );
                    element.url = data.url;
                    element.name = document.name;
                    element.storage_id = document.id;
                    tmp.push(element);
                } catch (error) {}
            }
        }
        setFiles(tmp);
        return tmp;
    };
    useEffect(() => {
        getData();
    }, [documents]);
    return (
        <>
            {files &&
                files.map((item, index) => {
                    return (
                        <React.Fragment key={`document_preview_${index}`}>
                            {item.mime_type.indexOf('image') !== -1 && item.url && (
                                <img
                                    src={item.url}
                                    alt={item.url}
                                    className='mw-100 mh-100'
                                />
                            )}
                            {item.mime_type.indexOf('image') === -1 && item.url && (
                                <iframe
                                    className='w-100 h-100'
                                    title={item.url}
                                    src={item.url}
                                />
                            )}
                        </React.Fragment>
                    );
                })}
        </>
    );
};
export default DocumentFrame;
